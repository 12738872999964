import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils/breakpoints';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import { scrollbar } from 'style/global.styles';

export const root = css`
  padding-bottom: ${spacing(40)};
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;

  ${scrollbar};

  @media ${breakpoints.smallMax} {
    padding-top: ${spacing(20)};
  }
`;

export const itemRoot = css`
  margin-bottom: ${spacing(24)};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const item = css`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const companyItem = css`
  display: flex;
  align-items: flex-start;
`;

export const message = css`
  padding: ${spacing(20)};
  background: hsl(var(--color-grayscale-9));
  border-radius: ${spacing(8)};
  order: -1;
  position: relative;
  min-width: 0;

  > p {
    ${font.size.small};
    color: hsl(var(--color-textLight-7));
  }

  &:hover {
    span {
      opacity: 1;
      top: 100%;
    }
  }
`;

export const companyMessage = css`
  ${message};
  order: 0;
  background: hsl(var(--color-grayscale-8));
`;

export const sentAt = css`
  opacity: 0;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  ${font.size.tiny};
  color: hsl(var(--color-grayscale-6));
  transition: opacity 0.3s, top 0.3s;
`;

export const avatar = css`
  width: calc(var(--unit) * 8);
  height: calc(var(--unit) * 8);

  span {
    display: flex;
    ${font.size.tiny};
    color: hsl(var(--color-white-11));
  }

  &:hover {
    cursor: default;
  }
`;

export const avatarNoImage = css`
  ${avatar};
  background: hsl(var(--color-error-7));
`;

export const talentAvatar = css`
  ${avatar};
  margin-left: ${spacing(12)};
`;

export const talentAvatarNoImage = css`
  ${avatarNoImage};
  margin-left: ${spacing(12)};
`;

export const companyAvatar = css`
  ${avatar};
  margin-right: ${spacing(12)};
`;

export const companyAvatarNoImage = css`
  ${avatarNoImage};
  margin-right: ${spacing(12)};
`;

export const day = css`
  ${font.size.tiny};
  color: hsl(var(--color-grayscale-6));
  margin-bottom: ${spacing(20)};
  text-align: center;
`;

export const loader = css`
  position: absolute;
  bottom: ${spacing(4)};
  right: ${spacing(4)};

  svg {
    height: ${spacing(12)};
    width: ${spacing(12)};
  }
`;

export const attachments = css`
  margin-top: ${spacing(16)};
`;
